import BigNumber from 'bignumber.js'
import React, { FC, Suspense, useEffect, useState } from 'react'

import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { ReactComponent as Poop } from '../../images/icons/emoji/poop.svg'
import { getUnWrappedNativeTokenSymbol, sleep } from '../../utils'
import { isPoop } from '../LimitsOptions/resources'
import Slide from '../Slide'

const AnimatedDrop: FC<{ showFeeValue?: boolean; readonly?: boolean }> = ({
  showFeeValue,
  readonly,
}) => {
  if (!showFeeValue && !readonly) {
    const AnimatedDropElement = React.lazy(() => import('../AnimatedDrop'))
    return <AnimatedDropElement isCost={true} />
  } else if (!showFeeValue && readonly) {
    return <div className="swap-verify__text">None...</div>
  }

  return <></>
}

interface NetworkFeeDesktopProps {
  tokenNetwork: string
  gasCosts?: BigNumber
  gasCostInNativeToken?: BigNumber
  fromAmountSelectedCurrency?: BigNumber
  title: string
  tooltipText: string
  readonly?: boolean
}

const NetworkFeeDesktop: FC<NetworkFeeDesktopProps> = ({
  tokenNetwork,
  gasCosts,
  fromAmountSelectedCurrency,
  gasCostInNativeToken,
  title,
  tooltipText,
  readonly,
}) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (gasCosts?.gte(0)) {
      sleep(1500).then(() => {
        setLoaded(true)
      })
    }
  }, [gasCosts])

  const showFeeValue = loaded && gasCosts?.gt(0)
  const feeSign = getUnWrappedNativeTokenSymbol(tokenNetwork)

  return (
    <>
      <div className="swap-verify-data__header">
        <div className="row row--no-gap row--align-center">
          <span className="label">{title}</span>
          <span className="tooltip-anchor" data-for="app-tooltip" data-tip={tooltipText} />
        </div>
      </div>
      <div className="swap-verify-data__body">
        {showFeeValue && (
          <div className="swap-verify__token-amount">
            <div className="swap-verify__col">
              <div className="swap-verify__nativefee">
                <Slide value={financialFormat(gasCostInNativeToken || 0)} className="value" />
                &nbsp;
                <span className="sign">{feeSign}</span>
              </div>
              <div className="swap-verify__usd">
                <span className="sign">~$</span>
                <Slide value={financialFormat(gasCosts || 0, { decimals: 2 })} className="value" />
              </div>
            </div>

            {gasCosts !== undefined &&
              fromAmountSelectedCurrency !== undefined &&
              isPoop(gasCosts, fromAmountSelectedCurrency) && <Poop className="shitty" />}
          </div>
        )}
        <Suspense fallback={<div />}>
          <AnimatedDrop showFeeValue={showFeeValue} readonly={readonly} />
        </Suspense>
      </div>
    </>
  )
}

export default NetworkFeeDesktop
